import {
    LitElement, html, customElement, property, css
  } from 'lit-element';
import "@github/time-elements"


  export class JMATimeUnix extends LitElement {

    constructor() {
        super();
      }

    static get properties() {
    return { 
        timestamp:{
            type: String
        },
        relative: {
          type: Boolean
        }
    };
    }

    render(){
        return html`
        ${!this.relative?html`
        <local-time datetime="${new Date(this.timestamp*1000).toISOString()}"
            month="long"
            day="numeric"
            year="numeric">
        </local-time>`
        :html`<relative-time datetime="${new Date(this.timestamp*1000).toISOString()}"></relative-time>`
        }
        
            
        `
    }

    
  }



  customElements.define('jma-time-unix', JMATimeUnix);