/** ELEMENTS */
import "@material/mwc-button";
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-ripple'

import "./elements/dropdown/morph-dropdown";
import "./elements/dropdown/morph-dropdown-item";
import "./elements/jma-time-unix"

import simpleParallax from 'simple-parallax-js';

//import {Color} from "./Color";

//console.log("hello From JS");
//console.log(Color.fromString("#ff0000"))
let body = document.body;


//paralax
var image = document.getElementsByClassName('paralax');
new simpleParallax(image, {scale: 1.3, overflow: false});


//fullscreen nav

let masthead = document.getElementById("masthead")
let toggleNavButton = document.getElementById("toggle-nav")
let toggleNavIcon = document.querySelector("#toggle-nav > i")
let fullscreenNav = document.getElementById("fullscreen-nav")
toggleNavButton.addEventListener("click", toggleNav);
function toggleNav() {
    toggleNavIcon.classList.toggle("fa-times")
    toggleNavIcon.classList.toggle("fa-bars")
    fullscreenNav.classList.toggle("open")
    masthead.classList.toggle("dim")
    body.classList.toggle("noscroll")
    /*if(fullscreenNav.classList.contains("open")) {
        toggleNavButton.classList.add("fa-times")
        toggleNavButton.classList.remove("fa-bars")
    } else {
        toggleNavButton.classList.remove("fa-times")
        toggleNavButton.classList.add("fa-bars")
    }*/   
    
}

